<template>
  <div class="block">
    <!--    <el-card>-->
    <!--      <el-row :gutter="12">-->
    <!--        <el-col :span="5" offset="4">-->
    <!--          <span>上班起号:{{ goodsTotal }}</span>-->
    <!--        </el-col>-->
    <!--        <el-col :span="5">-->
    <!--          <span>当前票号:{{ goodsTotal }}</span>-->
    <!--        </el-col>-->
    <!--        <el-col :span="5">-->
    <!--          <span>机内剩余票数:{{ goodsTotal }}</span>-->
    <!--        </el-col>-->
    <!--        <el-col :span="5">-->
    <!--          <el-button type="info" size="mini" @click="">修改</el-button>-->
    <!--        </el-col>-->
    <!--      </el-row>-->
    <!--    </el-card>-->
    <el-card>
      <el-form ref="addFormRef" :model="form" :rules="addFormRules" label-width="130px">
        <el-form-item label="人员类型" prop="personType">
          <el-radio-group v-model="form.personType" @change="handlePersonTypeChange">
            <el-radio-button
              v-for="item in personTypeList"
              :key="item.ID"
              :label="item.ID">{{ item.title }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="票种" prop="goods">
          <el-checkbox-group v-model="form.goods" @change="handleChange" v-if="form.personType != '1'">
            <div
              v-for="item in goodsList"
              :key="item.ID" style="margin-right: 50px;">
              <el-checkbox
                :label="item.ID">{{ item.title + " * " + item.price  }}
              </el-checkbox>

              <el-input-number v-model="item.num" @change="handleChange" :min="1" style="margin: 5px;">
              </el-input-number>
            </div>
          </el-checkbox-group>

          <el-radio-group v-model="form.goods[0]" @change="handleTeamChange" v-if="form.personType == '1'">
            <el-radio
              v-for="item in goodsList"
              :key="item.ID"
              :label="item.ID">{{ item.title + " * " + item.price  }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="票数" prop="num" v-if="form.personType == '1'">
          <el-input-number v-model="form.num" @change="handleTeamChange" :min="1">
          </el-input-number>
        </el-form-item>

        <el-form-item label="预定日期" prop="date">
          <el-date-picker
            v-model="form.date"
            align="right"
            type="date"
            placeholder="请选择日期"
            @change="handleChangeDate"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="出票方式" prop="ticketWay" v-if=" companyId.startsWith('57741ec0-5af4-11eb-8e20-61028293a')">
          <el-radio-group v-model="form.ticketWay">
            <el-radio
              v-for="item in ticketWayOptions"
              :key="item.value"
              :label="item.value"> {{ item.label }}
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="订单来源" prop="orderfrom" v-if="form.personType != '1'">
          <el-radio-group v-model="form.orderfrom" @change="handleOrderFromChange">
            <el-radio
              v-for="item in orderFromOptions"
              :key="item"
              :label="item">
            </el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="旅行社" prop="orderfrom" v-if="form.personType == '1'">
          <el-select v-model="form.orderfrom"
                     filterable
                     allow-create
                     default-first-option
                     placeholder="请选择旅行社">
            <el-option
              v-for="item in travelAgencyList"
              :key="item.ID"
              :label="item.机构名称"
              :value="item.ID">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="团队名称" prop="fromOrderNo" style="width: 350px;margin-top: 20px;" v-if="form.personType == '1'" >
          <el-input v-model="form.fromOrderNo" placeholder="请输入团队名称" />
        </el-form-item>

        <el-form-item label="支付方式" prop="orderType">
          <el-radio-group v-model="form.orderType" placeholder="请选择">
            <el-radio
              v-for="item in orderTypeOptions"
              :key="item.Value"
              :label="item.Value">{{ item.Key }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-input :placeholder="checkInfoHolder" v-model="form.fromOrderNo" style="width: 350px;" v-if="form.personType != '1'">
          <el-select v-model="checkInfo" slot="prepend" @change="changeCheckInfo" style="width: 130px;">
            <el-option
              v-for="item in checkInfoOptions"
              :key="item.value"
              :label="item.text"
              :value="item.value">
            </el-option>
          </el-select>
        </el-input>

        <el-form-item label="总价" style="width: 350px;margin-top: 20px;">
          <el-input v-model="totalMoney" disabled></el-input>
        </el-form-item>
        <el-form-item label="出票方式" prop="method" v-if="!companyId.startsWith('57741ec0-5af4-11eb-8e20-61028293a')">
          <el-radio-group v-model="form.method" placeholder="请选择出票方式" @change="handleChangeMethod">
            <el-radio
              v-for="item in methodOptions"
              :key="item.value"
              :label="item.value">{{ item.title }}
            </el-radio>
          </el-radio-group>
          <div class="sub-title" v-if="methodRemark">提示：{{ methodRemark }}</div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">确认</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import DateFormat from "@/utils/DateFormat.js";
export default {
  name: "sell",
  data() {
    return {
      form: {
        goods: [],
        num: 1,
        date: new DateFormat(new Date()).toString("yyyy-0m-0dT08:00:00"),
        orderfrom: '窗口售票',
        fromOrderNo: '',
        method: '1',
        orderType: 3,
        personType: '0',
        ticketWay: '2',
      },
      personTypeList: [{
        ID: '0',
        title: '散客',
      },{
        ID: '1',
        title: '团队',
      }],
      goodsList: [],
      methodOptions: [
        {
          title: "纸质票",
          value: "1",
        },
        {
          title: "电子票",
          value: "2",
        },
      ],
      // 出票方式提示内容
      methodRemark: '',
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now();
        },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '明天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() + 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }]
      },
      addFormRules: {
        goods: [{ required: true, message: "请选择票种", trigger: "blur" }],
        date: [{ required: true, message: "请选择日期", trigger: "blur" }],
        orderfrom: [{ required: true, message: "请选择", trigger: "blur" }],
        num: [{ required: true, message: "请选择票数", trigger: "blur" }],
        orderType: [{ required: true, message: "请选择支付方式", trigger: "blur" }],
        fromOrderNo: [{required: true, message: "请输入", trigger: "blur" }],
        ticketWay: [{required: true, message: "请选择", trigger: "blur" }],
      },
      totalMoney: 0,
      addDialogVisible: false,
      orderFromOptions: [
        "窗口售票",
        "票付通售票",
        "第三方售票"
      ],
      ticketWayOptions: [
        {
          label: '一票一人',
          value: '1',
        },
        {
          label: '一票多人',
          value: '2',
        }
      ],
      orderTypeOptions: [],
      travelAgencyList: [],
      checkInfo: '1',
      checkInfoHolder: '请输入身份证号',
      checkInfoOptions: [
        { text: '身份证号', value: '1'},
        { text: '手机号', value: '2'},
        { text: '订单号后六位', value: '3' },
      ],
      userId: window.sessionStorage.getItem("userId"),
      companyName: window.sessionStorage.getItem("companyName"),
      companyId: window.sessionStorage.getItem("companyId"),
    };
  },
  created() {
    console.log(this.companyId)
    if (!this.companyId) {
      this.$message({
        type: "error",
        message: "登录已过期!",
      });
      this.$router.push({
        path: "/mlogin"
      });
    }
    if ('0000-0000-0000-0000-0000-0001' == this.companyId || '0000-0000-0000-0000-0000-0002' == this.companyId) {
      this.sellDetail()
    } else {
      let params = {
        type: '0',
        ID: this.companyId,
        日期范围: 0,
      }
      this.getCommodity(params)
      this.getOrderTypeOptions()
      this.getTravelAgencyList(this.companyId)
    }
  },
  computed: {
  },
  methods: {
    getTravelAgencyList() {
      let that = this
      that.$axios({
        url: `/manager/teamList?timestemp=${ new Date().getTime() }`,
        method: "GET",
      })
        .then((res) => {
          if (res.code == 200) {
            this.travelAgencyList = res.data
          } else {
            that.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        });
    },
    getOrderTypeOptions() {
      let id = '2e8ac8b3-e201-4c29-91d6-c09deb7785a9'
      let that = this
      that.$axios({
        url: `/mall/enumDetail?id=${id}&timestemp=${ new Date().getTime() }`,
        method: "GET",
      })
        .then((res) => {
          if (res.code == 200) {
            this.orderTypeOptions = res.data
          } else {
            that.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
        });
    },
    getSubordinateScenceIds(id, params) {
      let that = this
      const loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      that.$axios({
        url: `/scenery/getSubordinateScenceIds?id=${id}&timestemp=${ new Date().getTime() }`,
        method: "GET",
      })
        .then(async (res) => {
          loading.close();
          if (res.code == 200) {
            for (let area of res.data) {
              params.ID = area.景区ID
              await this.getCommodity(params)
            }
          } else {
            that.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)
          loading.close();
        });
    },
   async getCommodity(data) {
      let that = this
      const loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      return new Promise(resolve => {
        that.$axios({
          url: `/scenery/getCommodity`,
          method: "POST",
          data,
        })
            .then((res) => {
              loading.close();
              if (res.code == 200) {
                if (!res.data.Pack || res.data.Pack.category.length < 1) {
                  return
                }
                let result = res.data.Pack.category[0].rank
                console.log(data.ID,'fanhui',result[0].title)
                const list = that.goodsList
                for(let i = 0; i < result.length; i++) {
                  const item = result[i]
                  if (!list || list.length < 1) {
                    item.num = 1
                    that.goodsList.push(item)
                  } else {
                    const refrainGoods = list.filter(g => (g.title == item.title))
                    if (!refrainGoods || refrainGoods.length < 1) {
                      item.num = 1
                      that.goodsList.push(item)
                    }
                  }
                }

                if (data.ID ==  that.companyId) {
                  this.getSubordinateScenceIds(that.companyId, data)
                }
                resolve(true)
              } else {
                resolve(true)
                that.$message.warning(res.msg)
              }
            })
            .catch((err) => {
              console.log(err)
              resolve(true)
              loading.close();
            });
      })

    },
    onSubmit() {
      this.$refs.addFormRef.validate((valid) => {
        if (!valid) {
          return;
        }
        if (new Date(this.form.date).getTime()<new Date().setHours(0,0,0)){
            this.$message({
              type: "warning",
              message: "预定日期不能在今天之前!",
            });
            return;
        }
        // if (!this.form.fromOrderNo) {
        //   this.$message({
        //     type: "warning",
        //     message: "请补充完整订单信息!",
        //   });
        //   return
        // }
        if (this.form.orderType===10){
          //拉卡拉扫码盒支付
          this.$prompt('请示意游客出示付款码', '提示', {
            cancelButtonText: '取消',
            showConfirmButton:false
          }).then(({ value }) => {
            if (!value){
              this.$message({
                type: 'warning',
                message: '未检测到付款码'
              });
            }else {
              this.genrateOrder(value)
            }

          }).catch(() => {

          });
        }else {
        this.$confirm("请确认已完成线下收款或三方平台票务核销", "确认兑票", {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            console.log("用户点击确认");
            this.genrateOrder()
          })
          .catch(() => {
            console.log("用户点击取消");
          })
        }

      });
    },
    createOrder(out_trade_no){
      let that = this
      const loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let goodsId = that.form.goods
      let detail = that.goodsDetail(goodsId)
      console.log(detail)
      let goods = []
      detail.forEach(d => {
        goods.push(
          {
            title: d.title,
            money: d.price,
            count: that.form.personType == '1' ? that.form.num : d.num,
            goodsId: d.ID,
            sceneryAreaId: d.scenreyAreaId,
            sceneryAreaName: d.scenreyAreaName,
          })
      })

      const img = window.sessionStorage.getItem("sceneryImg")
      let data = {
        goods,
        address: "",
        money: that.totalMoney,
        name: that.companyName,
        type: 0,
        sceneryAreaId: that.companyId,
        img,
        userid: that.userId,
        orderType: that.form.orderType,
        paystatus: false,
        useDate: that.form.date,
        orderfrom: that.form.orderfrom,
        fromOrderNo: that.form.fromOrderNo,
        personType: that.form.personType,
        是否取票: that.form.method == "1" ? "1" : "0",
        ticketWay: that.form.ticketWay,
      };

    },
    genrateOrder(auth_code='') {
      let that = this
      const loading = that.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      let goodsId = that.form.goods
      let detail = that.goodsDetail(goodsId)
      console.log(detail)
      let goods = []
      detail.forEach(d => {
        goods.push(
          {
            title: d.title,
            money: d.price,
            count: that.form.personType == '1' ? that.form.num : d.num,
            goodsId: d.ID,
            sceneryAreaId: d.scenreyAreaId,
            sceneryAreaName: d.scenreyAreaName,
          })
      })

      const img = window.sessionStorage.getItem("sceneryImg")
      let data = {
        goods,
        address: "",
        money: that.totalMoney,
        name: that.companyName,
        type: 0,
        sceneryAreaId: that.companyId,
        img,
        userid: that.userId,
        orderType: that.form.orderType,
        paystatus: true,
        useDate: that.form.date,
        orderfrom: that.form.orderfrom,
        fromOrderNo: that.form.fromOrderNo,
        personType: that.form.personType,
        是否取票: that.form.method == "1" ? "1" : "0",
        ticketWay: that.form.ticketWay,
      };
      if(auth_code) data.auth_code=auth_code
      let url = '/order/genrate'
      if (this.companyId.startsWith("57741ec0-5af4-11eb-8e20-61028293a") && this.form.personType != '1') {
        url = '/order/genrateYaoLi'
      }
      that.$axios({
        url,
        method: "POST",
        data,
      })
        .then((res) => {
          console.log(res);
          loading.close();
          if (res.code == 200) {
            that.$message({
              type: "success",
              message: "兑票成功!",
            });
            that.printTicket(res.data)
          } else {
            that.$message.warning(res.msg)
          }
        })
        .catch((err) => {
          console.log(err)

          that.$message.warning("网络异常，请稍后再试")
          loading.close();
        });
    },
    printTicket(order) {
      let date = new DateFormat(new Date(this.form.date)).toString("yyyy-0m-0d")
      console.log('开始打印票据', date)
      let path = "/message/goods"

      if (this.companyId.startsWith("57741ec0-5af4-11eb-8e20-61028293a")) {
        if (this.form.personType == '1') {
          order = [order]
        }

        path = "/message/ylGoods"
        const date1 = new Date(date)
        const date2 = new Date(date).setHours(date1.getHours() + 24)

        order.forEach(o => {
          o.qrUrl = this.genrateERCodeURL(o.ID)
          console.log('开始生成二维码', o.qrUrl)

          if (o.订单明细[0].是否通票 == "1") {
            o.游玩日期 = new DateFormat(date1).toString("yyyy-0m-0d") + " ~ " + new DateFormat(date2).toString("yyyy-0m-0d")
          } else {
            o.游玩日期 = new DateFormat(date1).toString("yyyy-0m-0d")
          }
        })

      } else {
        order.qrUrl = this.genrateERCodeURL(order.ID)
      }

      this.$router.push({
        path,
        query: {
          printInfo: JSON.stringify(order),
          date,
          method: this.form.method,
          personType: this.form.personType,
        },
      });
    },
    genrateERCodeURL(orderId) {
      if (this.form.method == '2') {
        return `?t=g&i=${
          orderId
        }&p=f`;
      } else {
        return `?t=c&i=${
          orderId
        }`;
      }
      // if (this.form.method == '2') {
      //   return `?type=genrate&ID=${
      //       orderId
      //     }&payType=false`;
      // } else {
      //   return `?type=check&ID=${
      //     orderId
      //   }`;
      // }
    },
    changeCheckInfo(value) {
      console.log(value)
      if (value == '1') {
        this.checkInfoHolder = '请输入身份证号'
      } else if (value == '2') {
        this.checkInfoHolder = '请输入手机号'
      } else if (value == '3') {
        this.checkInfoHolder = '请输入订单号后六位'
      }
    },
    handleChange(value) {
      console.log(this.form.goods);
      const items = this.goodsDetail(this.form.goods)
      this.totalMoney = 0
      items.forEach(item => {
        const money = item.price || 0
        const num = item.num
        this.totalMoney += money * num
      })

      console.log("总价", this.totalMoney);
    },
    handleTeamChange(value) {
      console.log(this.form.goods, value);
      const items = this.goodsDetail(this.form.goods)
      this.totalMoney = 0
      const money = items[0].price || 0
      const num = this.form.num
      this.totalMoney += money * num

      console.log("总价", this.totalMoney);
    },
    handleChangeDate(value) {
      console.log(value);
    },
    handleOrderFromChange(value) {
      console.log(value);
      if ("窗口售票" != value) {
        this.form.orderType = 1
      } else {
        this.form.orderType = 3
      }
    },
    handleChangeMethod(value) {
      console.log(value);
      if (value == '2') {
        this.methodRemark = '电子票由游客使用微信扫描二维码取票，并使用畅游小程序中的畅游码入园'
      } else {
        this.methodRemark = '纸质票由售票员打印出来给到游客，游客直接出示给验票员扫码验票后入园'
      }
    },
    handlePersonTypeChange(value) {
      console.log(value)
      this.goodsList = []
      this.form.goods = []
      this.form.num = 1
      this.form.orderfrom = this.form.personType == "1" ? '' : '窗口售票'
      this.form.fromOrderNo = ''
      this.form.method = '1'
      this.totalMoney = 0
      let params = {
        type: '0',
        ID: this.companyId,
        日期范围: 0,
        isTeamTicket: value,
      }
      this.getCommodity(params)
    },
    goodsDetail(ids) {
      let result = []
      ids.forEach(id => {
        const goods = this.goodsList.filter(o => o.ID == id)[0]
        result.push(goods)
      })
      return result
    },
    sellDetail() {
      this.$router.push({
        path: "/message/screens",
        query: {
          type: "9",
        },
      });
    },
  },
};
</script>
<style>
.sub-title{
  font-size: 12px;
  color: red;
}
</style>
